import React from 'react';
import '../cssFiles/HomeImgBanner.css';

function HomeImgBanner()
{
    return (
        <div className="homeimgBan">
            <body>
                <p>Alone we can do so little, together we can do so much</p>
                <p>- Helen Keller</p>
            </body>

        </div>
    );
}

export default HomeImgBanner;